// @flow

import { createReducer, createActions } from 'reduxsauce'
import Immutable from 'seamless-immutable'

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  AssembleInitiateRepo: [null],
  AssembleCommitRepo: ['repo'],
  AssembleRefreshRepo: ['repo'],
  AssembleResetRepo: ['repo'],
  AssembleCreateSimulation: ['repo', 'newSimName', 'marketingExp'],
  AssembleUpdateSimulation: ['repo', 'simId', 'data'],
  AssembleCreateSimulationComment: ['repo', 'simId', 'comment'],
  AssembleDeleteSimulationComment: ['repo', 'simId', 'commentId'],
  AssembleGetSimulations: ['repo'],
  AssembleLoadSimulation: ['repo', 'simId'],
  AssembleArchiveSimulation: ['repo', 'simId'],
  AssembleDeleteSimulation: ['repo', 'simId'],
  AssembleDuplicateSimulation: ['repo', 'simId', 'newSimName'],
  AssembleImportSimulation: ['repo', 'file'],
  AssembleGenerateSimulation: ['repo', 'simId'],
  AssembleExportSimulation: ['repo', 'simId'],
  AssembleGeneratePdf: ['repo', 'simId'],
  AssembleCreateSequence: ['repo', 'simId', 'data'],
  AssembleUpdateSequence: ['repo', 'simId', 'seqId', 'data'],
  AssembleDuplicateSequence: ['repo', 'simId', 'seqId'],
  AssembleTransferSequence: ['repo', 'simId', 'seqId', 'newSimId'],
  AssembleDeleteSequence: ['repo', 'simId', 'seqId'],
  AssembleCreateSequenceBranch: ['repo', 'simId', 'seqId', 'branch'],
  AssembleDeleteSequenceBranch: ['repo', 'simId', 'seqId', 'branch'],
  AssembleCreateSequenceComment: ['repo', 'simId', 'seqId', 'comment'],
  AssembleDeleteSequenceComment: ['repo', 'simId', 'seqId', 'commentId'],
  AssembleUpdateContainer: ['repo', 'simId', 'seqId', 'containerId', 'data'],
  AssembleCreateContainerComment: ['repo', 'simId', 'containerId', 'comment'],
  AssembleUpdateContainerComment: ['repo', 'simId', 'containerId', 'commentId', 'data'],
  // AssembleDeleteContainerComment: ['repo', 'simId', 'containerId', 'commentId'],
  AssembleUpdateSequenceOrder: ['repo', 'simId', 'sequences'],
  AssembleGetRecentActivity: ['simId'],
  AssembleLoadRecentActivity: ['simId'],
  AssembleSetUI: ['options'],
  AssembleSuccess: ['data'],
  AssembleFailure: ['error'],
})

export const AssembleTypes = Types
export default Creators

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  claims: null,
  data: null,
  error: null,
  success: null,
  fetching: false,
  repo: '',
  branch: '',
  simulations: [],
  simulation: '',
  comments: [],
  sequences: [],
  bg_color: '',
  simulationDownload: '',
  simulationPdf: '',
  image: '',
  name: '',
  simId: '',
  edit_round: 0,
  approved_by: null,
  approved_on: null,
  layout: '',
  app_name: '',
  app_host: '',
  web_app_name: '',
  web_host: '',
  app_icon: '',
  has_horizontal: false,
  present_url: '',
  script_url: '',
  description: '',
  tags: [],
  auto_run: 'false',
  recentActivity: [],
  marketingExp: '',
  mx_headline: '',
  mx_subhead: '',
  mx_button_text: '',
  mx_nav_prompt_text: '',
  options: {},
  importedSimId: '',
  training_video: '',
})

/* ------------- Reducers ------------- */

// We are starting an API call.
export const request = state => state.merge({ error: null, fetching: true, data: null })

// we've successfully completed an API call.
export const success = (state, { data }) =>
  state.merge({
    fetching: false,
    error: null,
    ...data,
    success: data.message,
  })

// we've had a problem with our API call.
export const failure = (state, { error }) =>
  state.merge({
    fetching: false,
    error: error.message ? error.message : 'Unknown Error',
    success: null,
    data: null,
  })

export const reset = () => INITIAL_STATE

export const setClaims = (state, { claims }) => state.merge({ claims })

export const doNothing = state => state

export const setUI = (state, { options }) => state.merge({ options })

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.ASSEMBLE_INITIATE_REPO]: request,
  [Types.ASSEMBLE_COMMIT_REPO]: request,
  [Types.ASSEMBLE_REFRESH_REPO]: request,
  [Types.ASSEMBLE_RESET_REPO]: request,
  [Types.ASSEMBLE_CREATE_SIMULATION]: request,
  [Types.ASSEMBLE_UPDATE_SIMULATION]: request,
  [Types.ASSEMBLE_CREATE_SIMULATION_COMMENT]: request,
  [Types.ASSEMBLE_DELETE_SIMULATION_COMMENT]: request,
  [Types.ASSEMBLE_GET_SIMULATIONS]: request,
  [Types.ASSEMBLE_LOAD_SIMULATION]: request,
  [Types.ASSEMBLE_ARCHIVE_SIMULATION]: request,
  [Types.ASSEMBLE_DELETE_SIMULATION]: request,
  [Types.ASSEMBLE_DUPLICATE_SIMULATION]: request,
  [Types.ASSEMBLE_IMPORT_SIMULATION]: request,
  [Types.ASSEMBLE_GENERATE_SIMULATION]: request,
  [Types.ASSEMBLE_EXPORT_SIMULATION]: request,
  [Types.ASSEMBLE_GENERATE_PDF]: request,
  [Types.ASSEMBLE_CREATE_SEQUENCE]: request,
  [Types.ASSEMBLE_UPDATE_SEQUENCE]: request,
  [Types.ASSEMBLE_DUPLICATE_SEQUENCE]: request,
  [Types.ASSEMBLE_TRANSFER_SEQUENCE]: request,
  [Types.ASSEMBLE_DELETE_SEQUENCE]: request,
  [Types.ASSEMBLE_CREATE_SEQUENCE_BRANCH]: request,
  [Types.ASSEMBLE_DELETE_SEQUENCE_BRANCH]: request,
  [Types.ASSEMBLE_CREATE_SEQUENCE_COMMENT]: request,
  [Types.ASSEMBLE_DELETE_SEQUENCE_COMMENT]: request,
  [Types.ASSEMBLE_UPDATE_CONTAINER]: request,
  [Types.ASSEMBLE_CREATE_CONTAINER_COMMENT]: request,
  [Types.ASSEMBLE_UPDATE_CONTAINER_COMMENT]: request,
  // [Types.ASSEMBLE_DELETE_CONTAINER_COMMENT]: request,
  [Types.ASSEMBLE_UPDATE_SEQUENCE_ORDER]: request,
  [Types.ASSEMBLE_GET_RECENT_ACTIVITY]: request,
  [Types.ASSEMBLE_LOAD_RECENT_ACTIVITY]: request,
  [Types.ASSEMBLE_SET_UI]: setUI,
  [Types.ASSEMBLE_SUCCESS]: success,
  [Types.ASSEMBLE_FAILURE]: failure,
})
