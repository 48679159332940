const common = {
  black: '#0D0D0D',
  white: '#FFFFFF',
  gray: {
    lightest: '#F9F9FB',
    lighter: '#ECECEC',
    light: '#E0E0E0',
    main: '#AEAEAE',
    darkish: '#D8D8D8',
    dark: '#7C7C7C',
    darker: '#4A4A4A',
    darkest: '#0D0D0D',
  },
}

const status = {
  success: '#05B083',
  warning: '#E8BE5D',
  error: '#DA4165',
  disabled: common.gray.main,
}

const primary = {
  main: '#FF3CAE',
  contrastText: '#FFFFFF',
}

const secondary = {
  lighter: '#0099E7',
  light: '#0852D7',
  lightish: '#033691',
  main: '#05348A',
  dark: '#1F2531',
  darker: '#001B4D',
  contrastText: '#FFFFFF',
}

const tertiary = {
  light: '#0199e7',
  main: '#44318D',
}

export const background = {
  paper: common.white,
  default: '#F9F9F9',
  primary: common.gray.lighter,
  primaryHue: primary.main,
  secondary: common.gray.main,
  secondaryHue: '#033691',
  secondaryDark: secondary.dark,
  ...status,
}

export const text = {
  primary: common.black,
  primaryHue: primary.main,
  secondary: common.gray.dark,
  secondaryHue: '#003959',
  secondaryHueDark: secondary.dark,
  secondaryHueLight: secondary.light,
  secondaryHueLightish: secondary.lightish,
  tertiary: common.gray.darker,
  tertiaryLight: common.gray.main,
  tertiaryHue: '#0099E7',
  tertiaryHueLight: '#3C94FB',
  blueAction: '#2851A3',
  light: '#979797',
  inverted: common.white,
  disabled: 'rgba(0, 0, 0, 0.38)',
  disabledDark: common.gray.dark,
  hint: 'rgba(0, 0, 0, 0.38)',
  inherit: 'inherit',
  success: '#41BF78',
  error: '#FF0000',
}

const bg = {
  paper: {
    main: background.paper,
    text: text.primary,
  },
  paperHover: {
    main: common.gray.lighter,
    text: text.primary,
  },
  inverted: {
    main: common.black,
    text: text.inverted,
  },
  default: {
    main: background.default,
    text: text.primary,
  },
  primary: {
    main: background.primary,
    text: text.primary,
  },
  primaryHue: {
    main: background.primaryHue,
    text: text.inverted,
  },
  secondaryHue: {
    main: background.secondaryHue,
    text: text.inverted,
  },
  secondary: {
    main: background.secondary,
    text: text.primary,
  },
  secondaryDarkish: {
    main: common.gray.darkish,
    text: text.tertiary,
  },
  secondaryDark: {
    main: background.secondaryDark,
    text: text.inverted,
  },
  success: {
    main: status.success,
    text: text.primary,
  },
  successLight: {
    main: '#9BE0B9',
    text: '#00471F',
  },
  warning: {
    main: status.warning,
    text: text.primary,
  },
  error: {
    main: status.error,
    text: text.primary,
  },
  disabledDark: {
    main: common.gray.dark,
    text: common.gray.dark,
  },
}

const builder = {
  100: {
    main: '#FAFAFC',
    contrastText: '#0D0D0D',
  },
  200: {
    light: '#E6E9F3',
    main: '#033691',
    dark: '#88A2CE',
    contrastText: '#0D0D0D',
  },
  300: {
    main: '#EAEEF6',
    contrastText: '#2851A3',
  },
  400: {
    main: '#3A65BD',
    contrastText: '#FFFFFF',
  },
  500: {
    main: '#152452',
    contrastText: '#FFFFFF',
  },
  600: {
    main: '#255BB0',
    contrastText: '#FFFFFF',
  },
  700: {
    main: '#2851A3',
    contrastText: '#FFFFFF',
  },
  900: {
    main: '#0D0D0D',
    contrastText: '#FFFFFF',
  },
}

const error = {
  main: '#CD040B',
  contrastText: '#FFFFFF',
}

const border = {
  main: '#E0E0E0',
  dark: '#979797',
  darker: common.gray.darker,
  light: '#F9F9FB',
  primary: '#E11C8E',
  disabled: common.gray.main,
  divider: common.gray.main,
}

const palette = {
  common,
  background,
  bg,
  primary,
  secondary,
  tertiary,
  builder,
  error,
  text,
  border,
  status,
}

export default palette
