import globals from './globals'
import spacing from './spacing'
import palette from './palette'
import breakpoints from './breakpoints'

const mixins = {
  // State
  hide: {
    opacity: 0,
    visibility: 'hidden',
  },
  show: {
    opacity: 1,
    visibility: 'visible',
  },
  // Layout
  container: {
    width: '100%',
    maxWidth: globals.containerWidth,
    marginLeft: 'auto',
    marginRight: 'auto',
    paddingLeft: 15,
    paddingRight: 15,
  },
  absoluteXCenter: {
    position: 'absolute',
    left: 0,
    right: 0,
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  absoluteCenter: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    margin: 'auto',
  },
  absoluteYCenter: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    marginTop: 'auto',
    marginBottom: 'auto',
  },
  marginXCenter: {
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  marginYCenter: {
    marginTop: 'auto',
    marginBottom: 'auto',
  },
  marginCenter: {
    margin: 'auto',
  },
  flexYCenter: {
    display: 'flex',
    alignItems: 'center',
  },
  flexXCenter: {
    display: 'flex',
    justifyContent: 'center',
  },
  flexCenter: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paddingX: space => ({
    paddingLeft: spacing(space),
    paddingRight: spacing(space),
  }),
  paddingY: space => ({
    paddingTop: spacing(space),
    paddingBottom: spacing(space),
  }),
  pX: space => `
    padding-left: ${spacing(space)}px;
    padding-right: ${spacing(space)}px;  
  `,
  pY: space => `
    padding-top: ${spacing(space)}px;
    padding-bottom: ${spacing(space)}px;  
  `,
  p: (space = []) => space.map(space => `${spacing(space)}px`).join(' '),
  padding: (space = []) => ({
    padding: space.map(space => `${spacing(space)}px`).join(' '),
  }),
  marginX: space => ({
    marginLeft: spacing(space),
    marginRight: spacing(space),
  }),
  marginY: space => ({
    marginTop: spacing(space),
    marginBottom: spacing(space),
  }),
  mX: space => `
    margin-left: ${spacing(space)}px;
    margin-right: ${spacing(space)}px;  
  `,
  mY: space => `
    margin-top: ${spacing(space)}px;
    margin-bottom: ${spacing(space)}px;  
  `,
  m: (space = []) => space.map(space => `${spacing(space)}px`).join(' '),
  margin: (space = []) => ({
    margin: space.map(space => `${spacing(space)}px`).join(' '),
  }),
  gridColumns: (gap = 0, inline = false, gridFlow = 'column') => ({
    display: inline ? 'inline-grid' : 'grid',
    gridAutoFlow: gridFlow === 'column' ? gridFlow : 'initial',
    gridTemplateColumns: gridFlow !== 'column' ? gridFlow : 'initial',
    gap: `${gap}px`,
  }),
  gridRows: (gap = 0, inline = false, gridFlow = 'row') => ({
    display: inline ? 'inline-grid' : 'grid',
    gridAutoFlow: gridFlow === 'row' ? gridFlow : 'initial',
    gridTemplateRows: gridFlow !== 'row' ? gridFlow : 'initial',
    gap: `${gap}px`,
  }),
  square: dimension => ({
    height: dimension,
    width: dimension,
  }),
  circle: dimension => ({
    height: dimension,
    width: dimension,
    borderRadius: '50%',
  }),
  // Other
  ifTouch: (props = {}) => ('ontouchstart' in document.documentElement ? props : {}),
  transition: (transitionArray = [], speed = globals.transitionSpeed, delay = 0) =>
    transitionArray.map(transition => `${transition} ${speed} ${delay}s ease-in-out`).join(', '),
  // borderRadius: (radii = []) => ({
  //   borderRadius: radii.map(radius => (`${radius}px`)).join(', ')
  // }),
  bg: (backgroundSize = 'cover', backgroundPosition = 'center', backgroundColor = palette.common.gray.light) => ({
    backgroundSize,
    backgroundPosition,
    backgroundColor,
    backgroundRepeat: 'no-repeat',
  }),
  runCSSFuncs: (props, cssFuncs) => {
    let css = ''
    const tempProps = props
    for (const propName in tempProps) {
      if (cssFuncs.hasOwnProperty(propName) && typeof tempProps[propName] !== 'object') {
        tempProps[propName] = { xx: tempProps[propName] }
      }
    }
    for (const bp in props.theme.breakpoints.values) {
      for (const propName in tempProps) {
        if (cssFuncs.hasOwnProperty(propName) && (tempProps[propName][bp] || tempProps[propName][bp] === 0)) {
          css += tempProps.theme.mixins.mq(cssFuncs[propName](tempProps[propName][bp], tempProps.theme), bp)
        }
      }
    }
    return css
  },
  mq: (styles, min = false, max = false) => {
    const minWidth = min ? `(min-width: ${breakpoints[min]}px)` : ''
    const maxWidth = max ? `(max-width: ${breakpoints[max] - 1}px)` : ''
    if (min === 'xx' && !max) return styles
    return `
      @media ${minWidth} ${min && max ? ' and ' : ''} ${maxWidth} { ${styles} }
    `
  },
  l_responsiveImage: {
    '& img': {
      maxHeight: '100%',
      maxWidth: '100%',
      height: 'auto',
      width: 'auto',
    },
    '& svg': {
      maxHeight: '100%',
      maxWidth: '100%',
      height: 'auto',
      width: 'auto',
    },
  },
  responsiveImage: {
    maxHeight: '100%',
    maxWidth: '100%',
    height: 'auto',
    width: 'auto',
  },
  scrim: {
    '&::before': {
      content: '""',
      position: 'absolute',
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      backgroundColor: 'rgba(0, 0, 0, .7)',
      transition: globals.transition,
    },
  },
}

export default mixins
