import { takeLatest } from 'redux-saga/effects'

/* ------------- Types ------------- */

import { AccountTypes } from '../Redux/AccountRedux'
import { AssembleTypes } from '../Redux/AssembleRedux'
// import { ApplicationTypes } from '../Redux/ApplicationRedux';
// import { TranslationTypes } from '../Redux/TranslationRedux';

/* ------------- Sagas ------------- */

import * as Account from './AccountSagas'
import * as Assemble from './AssembleSagas'

// import { create, update } from './ApplicationSagas';
// import { loadTranslation } from './TranslationSagas';

/* ------------- API ------------- */
import API from '../Services/api'
// The API we use is only used from Sagas, so we create it here and pass along
// to the sagas which need it.
// const api = DebugSettings.useFixtures ? FixtureAPI : API.create()
export const api = API.create()

/* ------------- Connect Types To Sagas ------------- */

export default function* root() {
  yield [
    // Account Sagas
    takeLatest(AccountTypes.ACCOUNT_GET_TOKEN, Account.getToken, api),
    takeLatest(AccountTypes.ACCOUNT_LOAD_COLLECTION, Account.loadCollection, api),
    takeLatest(AccountTypes.ACCOUNT_LOAD_PUBLIC_SIMULATION, Account.loadPublicSimulation, api),
    takeLatest(AccountTypes.ACCOUNT_SET_SIMULATION, Account.setSimulation, api),
    takeLatest(AccountTypes.ACCOUNT_SET_HORIZONTAL, Account.setHorizontal, api),
    takeLatest(AccountTypes.ACCOUNT_SET_PROFILE_OPTIONS, Account.setProfileOptions, api),
    takeLatest(AccountTypes.ACCOUNT_SET_TRIGGER, Account.setTrigger, api),
    takeLatest(AccountTypes.ACCOUNT_SEND_NOTIFICATION, Account.sendNotification, api),
    takeLatest(AccountTypes.ACCOUNT_SET_LEARN_MODE_TOUR_VISITED, Account.setLearnModeTourVisited),
    takeLatest(AccountTypes.ACCOUNT_CROSS_BROWSER_SEND_EVENT_TO_IFRAMES, Account.crossBrowserSendEventToIframes),
    takeLatest(AccountTypes.ACCOUNT_CROSS_BROWSER_RELAY_IFRAME_MESSAGE, Account.crossBrowserRelayIframeMessage),
    takeLatest(AccountTypes.ACCOUNT_CLEAR_SEND_EVENT, Account.crossBrowserSendEventToIframes),
    takeLatest(AccountTypes.ACCOUNT_CLEAR_RELAY_EVENT, Account.crossBrowserRelayIframeMessage),
    // Assemble Sagas
    takeLatest(AssembleTypes.ASSEMBLE_INITIATE_REPO, Assemble.initiateRepo),
    takeLatest(AssembleTypes.ASSEMBLE_COMMIT_REPO, Assemble.commitRepo),
    takeLatest(AssembleTypes.ASSEMBLE_REFRESH_REPO, Assemble.refreshRepo),
    takeLatest(AssembleTypes.ASSEMBLE_RESET_REPO, Assemble.resetRepo),
    takeLatest(AssembleTypes.ASSEMBLE_CREATE_SIMULATION, Assemble.createSimulation),
    takeLatest(AssembleTypes.ASSEMBLE_UPDATE_SIMULATION, Assemble.updateSimulation),
    takeLatest(AssembleTypes.ASSEMBLE_CREATE_SIMULATION_COMMENT, Assemble.createSimulationComment),
    takeLatest(AssembleTypes.ASSEMBLE_DELETE_SIMULATION_COMMENT, Assemble.deleteSimulationComment),
    takeLatest(AssembleTypes.ASSEMBLE_GET_SIMULATIONS, Assemble.getSimulations),
    takeLatest(AssembleTypes.ASSEMBLE_LOAD_SIMULATION, Assemble.loadSimulation),
    takeLatest(AssembleTypes.ASSEMBLE_ARCHIVE_SIMULATION, Assemble.archiveSimulation),
    takeLatest(AssembleTypes.ASSEMBLE_DELETE_SIMULATION, Assemble.deleteSimulation),
    takeLatest(AssembleTypes.ASSEMBLE_DUPLICATE_SIMULATION, Assemble.duplicateSimulation),
    takeLatest(AssembleTypes.ASSEMBLE_IMPORT_SIMULATION, Assemble.importSimulation),
    takeLatest(AssembleTypes.ASSEMBLE_GENERATE_SIMULATION, Assemble.generateSimulation),
    takeLatest(AssembleTypes.ASSEMBLE_EXPORT_SIMULATION, Assemble.exportSimulation),
    takeLatest(AssembleTypes.ASSEMBLE_GENERATE_PDF, Assemble.generatePdf),
    takeLatest(AssembleTypes.ASSEMBLE_CREATE_SEQUENCE, Assemble.createSequence),
    takeLatest(AssembleTypes.ASSEMBLE_UPDATE_SEQUENCE, Assemble.updateSequence),
    takeLatest(AssembleTypes.ASSEMBLE_DUPLICATE_SEQUENCE, Assemble.duplicateSequence),
    takeLatest(AssembleTypes.ASSEMBLE_TRANSFER_SEQUENCE, Assemble.transferSequence),
    takeLatest(AssembleTypes.ASSEMBLE_DELETE_SEQUENCE, Assemble.deleteSequence),
    takeLatest(AssembleTypes.ASSEMBLE_CREATE_SEQUENCE_BRANCH, Assemble.createSequenceBranch),
    takeLatest(AssembleTypes.ASSEMBLE_DELETE_SEQUENCE_BRANCH, Assemble.deleteSequenceBranch),
    takeLatest(AssembleTypes.ASSEMBLE_CREATE_SEQUENCE_COMMENT, Assemble.createSequenceComment),
    takeLatest(AssembleTypes.ASSEMBLE_DELETE_SEQUENCE_COMMENT, Assemble.deleteSequenceComment),
    takeLatest(AssembleTypes.ASSEMBLE_UPDATE_CONTAINER, Assemble.updateContainer),
    takeLatest(AssembleTypes.ASSEMBLE_CREATE_CONTAINER_COMMENT, Assemble.createContainerComment),
    takeLatest(AssembleTypes.ASSEMBLE_UPDATE_CONTAINER_COMMENT, Assemble.updateContainerComment),
    // takeLatest(AssembleTypes.ASSEMBLE_DELETE_CONTAINER_COMMENT, Assemble.deleteContainerComment),
    takeLatest(AssembleTypes.ASSEMBLE_UPDATE_SEQUENCE_ORDER, Assemble.updateSequenceOrder),
    takeLatest(AssembleTypes.ASSEMBLE_GET_RECENT_ACTIVITY, Assemble.getRecentActivity),
    takeLatest(AssembleTypes.ASSEMBLE_LOAD_RECENT_ACTIVITY, Assemble.loadRecentActivity),
  ]
}
